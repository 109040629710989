<template>
  <invoice-view :company="company" :order="order" :company-owner="ownerCompany" />
</template>

<script>
import InvoiceView from "@/views/dashboard/credit-order-detail/InvoiceView.vue";
import {get} from "@/services/api";

export default {
  name: "Invoice",
  components: {InvoiceView},
  data: () => ({
    order: {
      createdAt: "2022-05-06T16:38:06.000Z",
      credit: 2200,
      description: "Offer Credit",
      discount: 32,
      id: null,
      invoiceNumber: "TV002",
      ownerId: null,
      payments: [],
      price: 1500,
      status: "Payment confirmed",
      user: {
        country: "",
        email: "",
        fullName: "",
        id: 3,
        lastName: "",
        name: "",
        phone: '',
        status: 1,
        term: 1,
        updatedAt: "",
        username: null,
      }
    },
    company: null,
    ownerCompany: {},
  }),
  mounted() {
    let id = this.$route.params.id;
    get(`site/order-dummy/${id}`)
      .then(({data}) => {
        this.ownerCompany = JSON.parse(data.extraData);
      });
  }
}
</script>
